.navbar-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color: #000000;
    height: 10vh;
    z-index: 100;
}

.navbar-routes-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    flex-direction: row;
    list-style-type: none; 
}

.navbar-routes-elements a {
    color: #19E2FE;
    text-decoration: none; 
    position: relative;
    font-weight: 600;
}

.navbar-routes-elements a::after {
    content: ""; 
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #19E2FE;
    transition: width 0.3s; 
}

.navbar-routes-elements a:hover::after {
    width: 100%;
}

.navbar-brazil-flag img {
    position: relative;
    width: 25px;
    height: auto;
    cursor: pointer;
}

.navbar-logo img {
    position: relative;
    width: 60px;
    height: auto;
    cursor: pointer;
}

.footer-container {
    margin-top: 30px;
    background: linear-gradient(180deg, #121212 0%, #08313F 343.41%);
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-logo img {
    position: relative;
    width: 160px;
    height: auto;
    cursor: pointer;
    margin-left: 10vw;
}

.footer-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.footer-socials {
    margin: 1rem;
    display: flex;
    width: 20%;
    justify-content: space-between;
}

.footer-socials img {
    width: 20px;
    height: auto;
    cursor: pointer;
}

.footer-routes-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    flex-direction: row;
    margin-top: 22px;
    list-style-type: none; 
}

.footer-routes-elements a {
    color: #19E2FE;
    text-decoration: none; 
    position: relative;
    font-weight: 600;
}