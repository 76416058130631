.rotacoes-header {
    color: white;
    font-family: "Source Sans Pro", sans-serif;
}

.rotacoes-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dots {
    color: #19E2FE; 
}

.rotacoes-page-content {
    position: relative;
    margin-top: -45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.rotacoes-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 100px;
}
