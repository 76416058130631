.membro-header {
    color: white;
    margin-top: 3rem
}

.membros-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.dots {
    color: #19E2FE; 
}

.membros-page-content {
    position: relative;
    margin-top: -45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
}

.membros-buttons-container{
    display: flex;
    flex-wrap: wrap;
    padding: 3px 15px;
    border: 2px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    background: linear-gradient(60deg, #19E2FE,#1F54EE);
    color:white;
    cursor: pointer;
}

.circles-professores,
.circles-fundadores,
.circles-presidencia,
.circles-diretores1 {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    justify-content: center; 
}

.circle {
    width: 125px; 
    height: 120px; 
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin: 10px;
}

.page-content-fundadores{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
}

[data-tooltip]::before {
    position : absolute;
    content : attr(data-tooltip);
    background: white;
    padding: 4px;
    border-radius: 8px;
    opacity : 0;
}

[data-tooltip]:hover::before {
    opacity : 1;
}