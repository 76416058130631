.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 145vh;
    background-color: #000000;
}

.hero-text {
    position: absolute;
    font-size: 40vw;
    text-align: center;
    z-index: 0; 
    color: #19E2FE;
    transform: translateY(-11%);
    user-select: none;
}

.hero-image {
    position: relative; 
    max-width: 90%;
    height: auto;
    z-index: 1; 
    user-select: none;
}

.hero-subtitle {
    font-size: 1.2rem;
    color: white;
    margin-top: 1.5rem;
    z-index: 1;
    font-weight: 300;
}

.landing-page-socials {
    margin: 1.5rem;
    margin-top: 5rem;
    display: flex;
    width: 100%;
    max-width: 60%;
    justify-content: center;
}

.landing-page-socials a {
    margin: 10px;
    margin-top: 0;
    margin-bottom: 0;
}

.hero-logo-shadow {
    margin-top: -2rem;
    margin-bottom: 1rem;
    height: 15px;
    width: 70%;
    text-align: center;
    z-index: 0; 
    background-color: rgba(165, 165, 165, 0.336);
    user-select: none;
    border-radius: 50%;
    filter: blur(8px);
}