.rotacoes-button {
  padding: 10px 20px;
  font-size: 16px;
  background-image: linear-gradient(to right,#19E2FE, #1F54EE);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}
  
.rotacoes-button:hover {
  background-color: #0056b3;
  opacity: .9;
}  