
.page-background {
    background: linear-gradient(92.51deg, #19E2FE 0.48%, #1C9BF6 50.24%, #1F54EE 100%);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-background2 {
    background: black;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.black-one {
    background-color: black;
    width: 100%;
    height: 65vh;
    border-radius: 0 0 60% 60%;
    margin-bottom: 150px;
}

.black-two {
    background-color: black;
    width: 100%;
    height: 55vh;
    border-radius: 70% 70% 0 0;
    border: 1px solid black;
}

.background-headers {
    position: absolute;   
    top: -12%; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-header {
    width: 50%;
    color: white;
    margin-bottom: 1px;
    text-align: center;
    font-size: 3rem;
    z-index: 3;
    font-family: "SF Pro Display", sans-serif;
}

.background-subheader {
    width: 100%;
    color: white;
    font-size: 1.3rem;
    text-align: center;
    font-weight: 100;
    z-index: 3;
    font-family: "Source Sans Pro", sans-serif;
}

.background-balls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ball {
    position: absolute;
    display: flex;
    width: 100px;
    height: 100px;
    background: linear-gradient(135deg, #494955, #141414);
    border-radius: 100%;
    filter: blur(10px);
}

.first-ball {
    top: 20%;
    left: 10%;
    width: 90px;
    height: 90px;
}

.second-ball {
    top: 17%;
    left: 45%;
    width: 30px;
    height: 30px;
}

.third-ball {
    top: 25%;
    left: 80%;
    width: 90px;
    height: 90px;
}

.fourth-ball {
    top: 55%;
    left: 55%;
    width: 90px;
    height: 90px;
}

.fifth-ball {
    top: 60%;
    left: 25%;
    width: 45px;
    height: 45px;
}

.ball {
    transition: all 0.1s ease;
}