.rotacao-header {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    list-style-type: none; 
    font-size: 3rem;
    margin: 5px;
    font-family: "Source Sans Pro", sans-serif;
}

.rotacao-header-line {
    width: 15rem;
    height: 2px;
    background-color: #19E2FE;
    margin-bottom: 80px;
}

.rotacao-header h1 {
    color: #19E2FE;
    text-decoration: none; 
    position: relative;
    font-weight: 600;
}

.rotacao-header h1::after {
    content: ""; 
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    height: 2px;
    background-color: #19E2FE;
    width: 100%;
}

.rotacao-container {
    width: 100%;
    display: flex;
    justify-content: center;
}