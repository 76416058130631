.diretorias-container {
  width: 60%;
}

.diretoria-container {
  border: 1px solid #313131;
  border-radius: 15px;
  margin-bottom: 30px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  width: 100%;
  background: linear-gradient(to bottom,#1C1C1C, #050505);
  transition: all 0.3s;
}
  
.diretoria-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 5px;
  color: white;
  font-size: 1.3rem;
  font-family: "SF Pro Display", sans-serif;
  padding: 10px;
}

.diretoria-header h4 {
  margin: 0;
  font-weight: 600;
}

.diretoria-header span {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
}

.rotacao-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 5px;
  color: white;
  font-size: 1.3rem;
  font-family: "SF Pro Display", sans-serif;
  padding: 10px;

}

.diretoria-info {
  width: 50%;
  color: white;
}

.projeto-info {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  color: white;
}

.diretoria-info p {
  margin: 0;
}

.colored, .dots {
  color: #19E2FE;
}
