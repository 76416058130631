.top-gradient {
  background: linear-gradient(180deg, rgba(25, 226, 254, 0.3) 0%, rgba(31, 84, 238, 0) 100%);
  backdrop-filter: blur(2px);
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  padding: 71px 60px;
  box-sizing: border-box;
}

.sobre-alignment {
  display: flex;
  margin-top: 66px;
  width: 918px;
  max-width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.sobre-title {
  color: #ffff;
  align-self: center;
  font: bold 64px/80px SF Pro Display, sans-serif;
}

.sobre-box {
  border-radius: 20px;
  background-color: #18181c;
  display: flex;
  margin-top: 112px;
  flex-direction: column;
  font-size: 20px;
  color: #fff;
  line-height: 1.5;
  padding: 20px 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sobre-text {
  font: bold 64px/80px SF Pro Display, sans-serif;
  margin: 0 26px 0px 50px;
  font-size: 18px; 
  line-height: 2.2;
}

.half-logo {
  position: absolute;
  top: 33.85%;
  left: 61.45%;
  transform: translate(-50%, -50%);
}

.white-logo {
  position: center;
}

.distance-separator {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.distance-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.image-block {
  width: 75px;
  height: 75px;
  object-fit: cover;
  padding: 25px;
}

.text-block {
  font: 64px/80px SF Pro Display, sans-serif;
  margin: 10px -37px;
  color: #fff;
  text-align: center;
  font-size: 20px; 
  line-height: 2.2;
}

.separator {
  filter: blur(2px);
  background-color: #fff;
  width: 1px;
  height: 275px;
}

.distance-white-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 70px auto;
}

.page-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2));
}