.top-gradient {
    background: linear-gradient(180deg, rgba(25, 226, 254, 0.3) 0%, rgba(31, 84, 238, 0) 100%);
    backdrop-filter: blur(2px);
    display: flex;
    margin-top: 20px;
    width: 100%;
    align-items: center;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    padding: 71px 60px;
    box-sizing: border-box;
}
  
.contato-alignment {
display: flex;
margin-top: 66px;
width: 3000px;
max-width: 100%;
flex-direction: column;
box-sizing: border-box;
}

.contato-title {
color: #ffff;
align-self: center;
font: bold 48px/60px SF Pro Display, sans-serif;
}

.distance-separator {
display: flex;
flex-wrap: wrap;
gap: 85px;
justify-content: center;
width: 100%;
box-sizing: border-box;
}

.socials-distance {
display: flex;
flex-direction: column;
align-items: center;
gap: 0px;
}

.socials-blue-logo {
width: 95px;
height: auto;
object-fit: cover;
padding: 0px;
}
  
.socials-text {
    font: 64px/80px SF Pro Display, sans-serif;
    margin: 20px 0px;
    color: #fff;
    text-align: center;
    font-size: 28px; 
    line-height: 1;
}
  
.separator-contato {
    filter: blur(2px);
    background-color: #fff;
    width: 1px;
    height: 205px;
}

.contato-logo {
    display: block;
    margin: 0 auto; 
    padding: 60px;
}

.page-gradient {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2)); /* Valores de transparência ajustados para 0.2 */
}